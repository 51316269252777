<template>
  <header id="header_top">
    <nav class="navbar navbar-expand-lg navbar-light bg-white py-0" id="navbar">
      <div class="container">
        <a class="navbar-brand" href="/">
          <img
            src="/assets/img/logo.png"
            loading="lazy"
            width="150"
            class="img-fluid"
            alt="logo"
            id="logo"
          />
        </a>
        <button
          class="navbar-toggler border-0"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a class="nav-link ms-2 active" href="/">Accueil</a>
            </li>
            <li class="nav-item">
              <a class="nav-link ms-2" href="/#actualites">Actualités</a>
            </li>
            <li class="nav-item">
              <a class="nav-link ms-2" href="/#opportunites">Opportunités</a>
            </li>
            <li class="nav-item">
              <a class="nav-link ms-2" href="/#faqs">FAQs</a>
            </li>
            <li class="nav-item">
              <a class="nav-link ms-2" href="/#contacts">Contact</a>
            </li>
          </ul>
          <ul v-if="!isUserLogined" class="navbar-nav ms-auto mb-2 mb-lg-0">
            <div class="dropdown">
              <button
                class="btn btn-lg style_btn btn-primary px-4 dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Connexion
              </button>
              <ul
                class="dropdown-menu dropdown-menu-end py-0"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <router-link
                    class="dropdown-item text-wrap bg-primary text-white py-3"
                    :to="{ name: 'accounts.login.de' }"
                    >Demandeur d'emploi</router-link
                  >
                </li>
                <li>
                  <router-link
                    class="dropdown-item text-wrap bg-success text-white py-3"
                    :to="{ name: 'accounts.login.ent' }"
                    >Offreur d'emploi</router-link
                  >
                </li>
                <li>
                  <router-link
                    class="dropdown-item text-wrap bg-yellow text-white py-3"
                    :to="{ name: 'accounts.login.agent' }"
                    >Service publique de l'emploi</router-link
                  >
                </li>
              </ul>
            </div>
          </ul>
          <ul v-else class="navbar-nav ms-auto mb-lg-0">
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownPropos"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src="/assets/img/profilMan.jpg"
                  width="40"
                  height="40"
                  class="rounded-circle border me-2"
                  alt="..."
                />
                {{ $user.username }}</a
              >
              <ul
                class="dropdown-menu dropdown-menu-end py-0"
                aria-labelledby="navbarDropdownPropos"
              >
                <li>
                  <!-- :to="{
                      name: `espace.common.azolis.home`,
                      params: { userRole: this.$userRoleName },
                    }" -->
                  <!-- <router-link
                    :to="{ name: `${this.currentUserHome}`,  }"
                    class="dropdown-item text-wrap py-3"
                    >Mon espace</router-link
                  > -->

                  <router-link
                    :to="{
                      name: 'espace.common.user.home',
                      params: { userRole: ($userRoleName || '').toLowerCase() },
                    }"
                    class="dropdown-item text-wrap py-3"
                    >Mon espace</router-link
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item text-wrap py-3"
                    :to="{ name: currentUserProfile }"
                    >Mon Profil</a
                  >
                </li>
                <li>
                  <a class="dropdown-item text-wrap py-3" @click.prevent="logout" href="#"
                    >Déconnexion</a
                  >
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import { authMixin } from "../../mixins/auth-mixin";
export default {
  mixins: [authMixin],
};
</script>

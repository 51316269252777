var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{attrs:{"id":"header_top"}},[_c('nav',{staticClass:"navbar navbar-expand-lg navbar-light bg-white py-0",attrs:{"id":"navbar"}},[_c('div',{staticClass:"container"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navbarSupportedContent"}},[_vm._m(2),(!_vm.isUserLogined)?_c('ul',{staticClass:"navbar-nav ms-auto mb-2 mb-lg-0"},[_c('div',{staticClass:"dropdown"},[_c('button',{staticClass:"btn btn-lg style_btn btn-primary px-4 dropdown-toggle",attrs:{"type":"button","id":"dropdownMenuButton1","data-bs-toggle":"dropdown","aria-expanded":"false"}},[_vm._v(" Connexion ")]),_c('ul',{staticClass:"dropdown-menu dropdown-menu-end py-0",attrs:{"aria-labelledby":"dropdownMenuButton1"}},[_c('li',[_c('router-link',{staticClass:"dropdown-item text-wrap bg-primary text-white py-3",attrs:{"to":{ name: 'accounts.login.de' }}},[_vm._v("Demandeur d'emploi")])],1),_c('li',[_c('router-link',{staticClass:"dropdown-item text-wrap bg-success text-white py-3",attrs:{"to":{ name: 'accounts.login.ent' }}},[_vm._v("Offreur d'emploi")])],1),_c('li',[_c('router-link',{staticClass:"dropdown-item text-wrap bg-yellow text-white py-3",attrs:{"to":{ name: 'accounts.login.agent' }}},[_vm._v("Service publique de l'emploi")])],1)])])]):_c('ul',{staticClass:"navbar-nav ms-auto mb-lg-0"},[_c('li',{staticClass:"nav-item dropdown"},[_c('a',{staticClass:"nav-link dropdown-toggle",attrs:{"href":"#","id":"navbarDropdownPropos","role":"button","data-bs-toggle":"dropdown","aria-expanded":"false"}},[_c('img',{staticClass:"rounded-circle border me-2",attrs:{"src":"/assets/img/profilMan.jpg","width":"40","height":"40","alt":"..."}}),_vm._v(" "+_vm._s(_vm.$user.username))]),_c('ul',{staticClass:"dropdown-menu dropdown-menu-end py-0",attrs:{"aria-labelledby":"navbarDropdownPropos"}},[_c('li',[_c('router-link',{staticClass:"dropdown-item text-wrap py-3",attrs:{"to":{
                    name: 'espace.common.user.home',
                    params: { userRole: (_vm.$userRoleName || '').toLowerCase() },
                  }}},[_vm._v("Mon espace")])],1),_c('li',[_c('a',{staticClass:"dropdown-item text-wrap py-3",attrs:{"to":{ name: _vm.currentUserProfile }}},[_vm._v("Mon Profil")])]),_c('li',[_c('a',{staticClass:"dropdown-item text-wrap py-3",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.logout.apply(null, arguments)}}},[_vm._v("Déconnexion")])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"navbar-brand",attrs:{"href":"/"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":"/assets/img/logo.png","loading":"lazy","width":"150","alt":"logo","id":"logo"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"navbar-toggler border-0",attrs:{"type":"button","data-bs-toggle":"collapse","data-bs-target":"#navbarSupportedContent","aria-controls":"navbarSupportedContent","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('span',{staticClass:"navbar-toggler-icon"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"navbar-nav mx-auto mb-2 mb-lg-0"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link ms-2 active",attrs:{"href":"/"}},[_vm._v("Accueil")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link ms-2",attrs:{"href":"/#actualites"}},[_vm._v("Actualités")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link ms-2",attrs:{"href":"/#opportunites"}},[_vm._v("Opportunités")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link ms-2",attrs:{"href":"/#faqs"}},[_vm._v("FAQs")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link ms-2",attrs:{"href":"/#contacts"}},[_vm._v("Contact")])])])
}]

export { render, staticRenderFns }